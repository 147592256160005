import React from 'react';
import riddle from './riddle';

const Footer = ({ qNumber, goNext, setFade }) => {
  let answerClass;
  let src, alt, title;
  if (qNumber < 16) {
    answerClass = 'answer';
    src = 'yarn.png';
    alt = '실뭉치';
    title = '실뭉치';
  } else {
    answerClass = 'answer darkInput'
    src = 'sword.png';
    alt = '칼';
    title = '칼';
  }

  const checkAnswer = () => {
    const answer = document.querySelector('.answer');
    const noticeP = document.querySelector('.notice');
    if (answer.value === window.atob(window.atob(riddle[qNumber].answer))) {
      setFade();
      setTimeout(() => goNext(qNumber + 1), 500);
      answer.value = '';
    } else {
      noticeP.textContent = '정답이 아닙니다.';
    }
  }

  const pressEnter = () => {
    if (window.event.keyCode === 13) {
      checkAnswer();
    }
  }

  const changeValue = () => {
    const noticeP = document.querySelector('.notice');
    if (noticeP.innerHTML) {
      noticeP.innerHTML = '';
    }
  }

  return (
    <footer className="footer">
      <p className="notice"></p>
      <div className="answerContainer">
        <input className={answerClass} onChange={changeValue} onKeyUp={pressEnter}></input>
        <button className="submitAnswer" onClick={checkAnswer}>
          <img
            src={src}
            alt={alt}
            title={title} />
        </button>
      </div>
    </footer>
  );
};

export default Footer;