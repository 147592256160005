import React from 'react';
import riddle from './riddle';

// { } 로 까서 넣어줘야됨
const Content = ({ qNumber }) => {
  let riddleClass;
  if (qNumber < 16 || qNumber > 22) {
    riddleClass = 'riddleP';
  } else {
    riddleClass = 'riddleP darkFont'
  }
  return (
    <section className="content">
      <p className={riddleClass}>
        {riddle[qNumber].p1 ? riddle[qNumber].p1.map((r, i) => {
          return (
            <React.Fragment key={i}>{r}<br /></React.Fragment>
          )
        }) : ''}
      </p>
      <p className={riddleClass}>
        {riddle[qNumber].img ? riddle[qNumber].img.map((src, i) => {
          return (
            <React.Fragment key={i}>
              <img className="riddleImg" src={src} alt={src}/>
            </React.Fragment>
          )
        }) : ''}
      </p>
      <p className={riddleClass}>
        {riddle[qNumber].p2 ? riddle[qNumber].p2.map((r, i) => {
          return (
            <React.Fragment key={i}>{r}<br /></React.Fragment>
          )
        }) : ''}
      </p>
      {/* {riddle[qNumber].p1.map((r, i) => {
        return (
          <p className="riddleP" key={i}>{r}</p>
        )
      })} */}
    </section>
  );
};

export default Content;