import React from 'react';
import './App.css';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import Modal from 'react-modal';
import riddle from './riddle';
// import Antidote from './Antidote';

function App() {
  const [num, setNum] = React.useState(0);
  const [modalOpen, setModalOpen] = React.useState(false);

  const setFade = () => {
    const app = document.querySelector('.App');
    app.classList.add('fadeOut');
    app.classList.remove('fadeIn');
    setTimeout(() => {
      if (num < 23) {
        setNum(num + 1);
      } else {
        setNum(0);
      }
      app.classList.remove('fadeOut');
      app.classList.add('fadeIn');
    }, 500);
  }

  const setModal = () => setModalOpen(!modalOpen);

  const setQNumber = newNum => {
    setNum(newNum);
    if (newNum > 15 && newNum < 23) {
      const root = document.getElementById('root');
      root.classList.add('darkbg');
    } else {
      const root = document.getElementById('root');
      if (root.classList.contains('darkbg')) {
        root.classList.remove('darkbg');
      }
    }
  }

  Modal.setAppElement('#root');

  return (
    <div className="App">
      <Modal 
        closeTimeoutMS={250}
        className="modal"
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
      >
        {riddle[num].hint.map((hint, i) => {
          return (
          <p className="hint" key={i}>{hint}</p>
        )})}
        <button className="modalCloseBtn" onClick={() => setModalOpen(false)}>
          &#215;
        </button>
      </Modal>
      <Header qNumber={num} modalOpen={modalOpen} setModal={setModal}/>
      {/* {(num < 24) 
        ? <Content qNumber={num} />
        : <Antidote />
      } */}
      <Content qNumber={num} />
      {(num === 0 || num > 22) 
      ? <button className="startBtn" onClick={setFade}>
          <img 
            src="yarn.png" 
            alt="실뭉치" 
            title="실뭉치" 
          />
        </button> 
      : <Footer qNumber={num} goNext={setQNumber} setFade={setFade} />}
    </div>
  );
}

export default App;