import React from 'react';
import riddle from './riddle';

const Header = ({ qNumber, setModal }) => {
  return (
    <header className="header">
      <div className="dummy"></div>
      <div className="title">
        {riddle[qNumber].title}
      </div>
      <button className="bookmark" onClick={setModal}>
        <img 
          src="butterfly.png" 
          alt="나비"
          title="나비"
        />
      </button>
    </header>
  );
};

export default Header;