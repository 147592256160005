const riddle = [
  {
    title: '안내',
    p1: [
      `* 나인스토리에서 제작한 뮤지컬 ‘아가사’(2021) 기반 추리/미궁 게임입니다.`,
      `* 작품에 없는 창작 요소도 붙였지만, 기본적으로 작품 줄거리를 이용해서 문제를 냈습니다. 아직 관람하지 않으신 분들은 나중의 재미를 위해 풀지 않으시기를 권해드립니다.`,
      `* 정답을 메모하며 문제를 풀어주세요. 이전 문제들의 정답을 조합해 풀어야 하는 문제도 있습니다.`,
      `* 모든 정답은 영문 소문자 혹은 숫자이며, 대부분의 문제는 제목과 정답에 연관성이 있습니다.`,
      `* 힌트는 나비 모양 북마크를 클릭해서 볼 수 있습니다.`,
      `* PC에 최적화되어 있습니다.`,
      `* 이 미궁 게임의 제작자는 트위터의 @_apparitionSky 입니다.`
    ],
    hint: ['문제마다 힌트가 나타납니다.']
  },
  {
    title: '1. 미궁 속의 티타임',
    p1: [
      '당신은 지금 입구에 서 있습니다.',
      '실종된 아가사 크리스티를 찾기 위해 ',
      '당신이 방문하려는 곳은 어딘가요?'
    ],
    answer: 'YzNSNWJHVno=',
    hint: [
      '“그거 알아? 이 저택 이름.”'
    ]
  },
  {
    title: '2. 실종',
    img: [
      '2.png'
    ],
    answer: 'WlhacFpHVnVZMlU9',
    hint: [
      '기사에 적힌 글자 개수를 잘 관찰해주세요. 띄어쓰기와 구두점은 제외합니다.',
      '8글자'
    ]
  },
  {
    title: '3. 펜은 칼보다 강하다',
    p1: [
      '“레이몬드, 네가 알아야 할 게 있어요.',
      '언론의 힘은 생각보다 세다는 거.',
      '아가사의 실종이 사방에 알려졌고, 이제 모르는 사람이 없다구.”'
    ],
    p2: ['⬆️➡️⬅️⬇️'],
    answer: 'Ym1WM2N3PT0=',
    hint: [
      '방향을 영어로 읽어 보세요.',
      '4글자'
    ]
  },
  {
    title: '4. 주인공',
    p1: [
      `에릭 헤리츠 경감님이 도착하셨습니다.`,
      `다음은 12월 3일, 스타일스 저택에 방문한 사람들이 증언한 내용입니다.`
    ],
    img: [
      'clock.png'
    ],
    p2: [
      '아치볼드 크리스티',
      '오전 7시-11시 :: 아침 식사 후 가벼운 운동, 외출',
      '오전 11시-오후 1시 :: 점심 식사',
      '오후 1시-3시 :: 서재에서 업무를 봄. 그의 비서가 알리바이',
      '오후 3시-9시 :: 줄곧 집에 있었으며 티타임 참석 이후에는 곧장 방으로 들어감. 집사는 적어도 오후 9시에 크리스티 씨가 방에 있었음을 증언',
      '',
      '폴 뉴트란',
      '오전 11-오후 1시 :: 신문사 동료와 점심 식사',
      '오후 1시-5시 :: 신문사로 돌아와 오후 업무',
      '오후 5시-7시 :: 스타일스 저택으로 출발 및 도착, 티타임 참석',
      '오후 7시-11시 :: 티타임 직후 마작을 하러 감. 친구가 알리바이. 이후 마부를 불러 귀가',
      '',
      '뉴먼 편집장',
      '오후 12시 :: 점심 식사',
      '오후 6시 :: 티타임 참석을 위해 일찍 도착. 티타임 직후 마부를 불러 귀가',
      '',
      '아가사 크리스티',
      '오전 7시-11시 :: 아침 식사, 딸과 가벼운 산책 후 서재에 머무름',
      '오전 11시-오후 1시 :: 점심 식사 후 서재에서 자주 방문하던 이웃집 아이 레이몬드 애쉬튼과 만남',
      '오후 1시-3시 :: 애쉬튼을 돌려보낸 후 하녀가 가져다준 홍차를 마심. 낮잠',
      '오후 3시-9시 :: 티타임 전까지 서재에 있었으며, 평소와 다를 바 없었다고 하녀들과 집사가 증언',
      '오후 9시 :: 딸을 재우고 홍차를 마심. 하녀 베스가 증언',
      '오후 9시-4일 오전 5시 :: 목격자 없음. 오전 5시경 일찍 일어난 하녀가 실종자가 집에 없는 것을 확인',
      '',
      '베스',
      '오전 11시-오후 1시 :: 크리스티 부부의 식사를 준비한 후 점심 식사',
      '오후 1시-5시 :: 크리스티 부부의 차와 커피, 다과를 손수 준비. 하녀들에게 티타임 차림 등을 지시',
      '오후 5시-7시 :: 티타임 준비, 손님을 맞음',
      '오후 7시-11시 :: 티타임 참석 후 뒷정리를 감독, 일찍 잠자리에 들음',
      '',
      '레이몬드 애쉬튼',
      '오전 11시-오후 1시 :: 집에서 점심 식사 후 실종자의 저택에 방문',
      '오후 12시-6시 :: 실종자와 만난 후 귀가. 오후 내내 집에 있었음을 가정 교사가 입증'
    ],
    answer: 'Y0c5cGNtOTA=',
    hint: [
      '시계를 보고, 사람들의 알리바이가 주어진 시간을 관찰하세요. 한 사람씩 알리바이가 있는 시간을 따라가다 보면 무언가 보입니다.',
      '“탐정이 주인공이지, 범인이 주인공은 아니니까요.”'
    ]
  },
  {
    title: '5. 하녀',
    p1: [
      '이해를 돕기 위해 아가사 크리스티의 서재 평면도 및 가구 배치를 제시합니다.'
    ],
    img: [
      '5.png'
    ],
    p2: [
      '“아가씨는 따님을 재우시고 나서 홍차를 마셨어요.',
      '아무도 아가씨가 밖으로 나가신 걸 몰랐죠.',
      '만약 나쁜 일이 생겼다면……”',
      '',
      '“유산이라니요? 전 아가씨를 제 딸처럼 키웠어요.',
      '전 신문에서 떠들어대는 유산에는 아무 관심도 없어요.”'
    ],
    answer: 'WW5WMGRHVnlabXg1',
    hint: [
      '방에 놓인 가구가 칠해진 색과 모양에 주목하세요.',
      '9글자'
    ]
  },
  {
    title: '6. 편집장',
    p1: [
      '“그 날 저녁에 아가사와 얘길 좀 했죠. ',
      '<푸른 열차의 살인> 원고가 언제쯤 완성되겠냐고 물었는데.',
      '에르퀼 푸아로 시리즈는 셜록 홈즈만큼, 아니 그보다 더 유명해질 수 있을 거요.',
      '그렇게 뛰어난 작가는 흔치 않은데…”',
      '',
      '“판매 부수가 늘어난 건 사실이지. ',
      '하지만 그건 작가에게도 좋은 일 아니오?”'
    ],
    p2: [
      '🐭🐜🍜🦄🐿️🐈🐰🧊🐷🐯'
    ],
    answer: 'YldGdWRYTmpjbWx3ZEE9PQ==',
    hint: [
      '그림들을 보고, 영어로 써 보세요.',
      '10글자'
    ]
  },
  {
    title: '7. 남편',
    p1: [
      '“자살이요?',
      '남편으로서 말씀드리지만 제 아내는 극단적인 선택을 하지 않았을 겁니다.',
      '모든 걸 가진 여자니까요.”',
      '',
      '“내가 부정이라도 저질렀다는 겁니까?',
      '함부로 이야기하지 마십시오.”'
    ],
    p2: [
      '.--. . .- .-. .-..'
    ],
    answer: 'Y0dWaGNtdz0=',
    hint: [
      '무선 통신에서 자주 쓰이는 것으로, 검색이 필요합니다.',
    ]
  },
  {
    title: '8. 12월 3일',
    p1: [
      `‘티타임에 참석했던 모두가 알리바이를 갖고 있지만,`,
      `그들은 또한 묘한 이득도 보고 있어.`,
      `아가사의 소설 속 탐정 푸아로라면 이 상황에서 어떻게 추리했을까?’`
    ],
    p2: [
      'The _urder of Roger Ackryod',
      'Po_rot’s Early Cases',
      'The Mysterious Affair at _tyle_',
      'While the L_ght Lasts',
      'The Murder on the Li_ks',
      'Poirot Investi_ates'
    ],
    answer: 'YldsemMybHVadz09',
    hint: [
      "뭔가 ‘빠져’있지 않나요?",
      '7글자'
    ]
  },
  {
    title: '9. 작은 회색 뇌세포',
    img: [
      '9.png'
    ],
    answer: 'ZEhKcFkycz0=',
    hint: [
      '5글자'
    ]
  },
  {
    title: '10. 공범?',
    p1: [
      '“경감님! 이건 <미궁 속의 티타임>이에요.',
      '…착한 사람 눈엔 보이죠.”'
    ],
    img: [
      '10.png'
    ],
    p2: [
      `‘…책이 움직이다가, 어느 순간 사라졌다?`,
      `레이몬드, 침착하자. 본질은 책이 정말 존재하는지 여부가 아니야!`,
      `똑바로 볼 수 없게 방해하는 건 다 정리해야 해.’`
    ],
    answer: 'Wlc1bGJYaz0=',
    hint: [
      '‘똑바로 볼 수 없게 방해하는 건 다 정리해야 해.’',
      '5글자'
    ]
  },
  {
    title: "11. 애쉬튼, 용의자들을 초대하다",
    p1: [
      '“…이들은 모두 책을 손에 넣으려고 했어.',
      '그런데 만약, 이들이 책을 미룬 게 아니라 넘겨준 거라면?',
      '이들은 서로를 음해하려는 게 아니야, 거미줄처럼 연결돼 있어.',
      '그걸 아가사도 알았던 거야!”',
      ''
    ],
    p2: [
      "당신의 죄악이 주인공인 추리소설을 발표하기 위해",
      "붉은 홍차의 향연이 펼쳐질 티타임에 초대합니다.",
      "암호 : ",
      "0 0 1 0 0 3 ―",
      "3 0 1 1 0 1 │"
    ],
    answer: 'Ylc5MGFYWmw=',
    hint: [
      '수평, 수직선을 긋고 어떤 글자가 나타나는지 관찰해 보세요. 추리도 필요합니다.',
      '6글자'
    ]
  },
  {
    title: '12. 꼬마 탐정의 작은 모임',
    p1: [
      '“그녀는 대위님을 의지하고 싶었을 거예요. 하지만 당신은 그녀를 버리려 했죠.”',
      '“베스, 당신은 사랑에 빠진 당신 딸을 보호하려고 그녀를 배신했어요!”',
      '“그리고 폴, 당신은 이 사실을 알고 베스를 협박해서 아가사의 사생활을 캐내고',
      '작품에 대한 악평만 쏟아냈죠.”',
      '“뉴먼, 당신은 출판 계약을 마친 뒤 줄곧 그녀를 압박했어요. ',
      '판매가 늘어날 거라는 명목으로 그녀의 글을 난도질하려 했죠!”',
      '',
      '“당신들 모두가 범인이야!”'
    ],
    img: [
      'typewriter.png'
    ],
    p2: [
      '1-5 1-4 1-7 1-5 2-6'
    ],
    answer: 'ZEhKMWRHZz0=',
    hint: [
      '정답을 작성하기 전 잠깐 손을 내려다보세요.',
      '5글자'
    ]
  },
  {
    title: '13. 초대받지 않은 손님',
    p1: [
      '“이봐, 꼬마 탐정. 수수께끼는 끝까지 풀어야지. 난 왜 편집장과 결탁했을까?',
      '난 말야, 늘 아가사를 뛰어넘는 최고의 추리소설 작가가 되고 싶었어.',
      '하지만 내가 아무리 협박해도 그녀는 단 한 번도 아이디어를 넘긴 적이 없었지.',
      '대신 내게는 믿을만한 정보원이 있었어. 조금 띄워주니 우쭐해져서 다 털어놓던 정보원!',
      '레이몬드, 나야. 알아보겠어? 네 펜팔 친구 N! 편지 고마웠다?”',
      '',
      '“너도 공범이었네, 꼬마 탐정.”'
    ],
    img: [
      '13.png'
    ],
    answer: 'ZEdocFpXWT0=',
    hint: [
      '그림을 보고 연상되는 것을 써 보세요.',
      '5글자'
    ]
  },
  {
    title: '14. 천재 작가, 멋진 탐정',
    p1: [
      '“내가 그녀를 사라지게 했어, 내가 그녀를 죽게 했어, ',
      '모든 게 나 때문이야, 내가……”'
    ],
    p2: ['1920182538 9'],
    answer: 'YzNSeWVXTm9ibWx1WlE9PQ==',
    hint: [
      '숫자는 무언가에 대응하는 코드입니다.',
      '단, 마지막에 혼자 떨어진 9는 그냥 9입니다.'
    ]
  },
  {
    title: '15. 중심',
    img: [
      '15_1.png',
      '15_2.png'
    ],
    p2: [
      '당신은 지금 또다른 입구에 서 있습니다.',
      '실종된 아가사 크리스티를 찾기 위해 ',
      '당신이 방문하려는 곳은 어딘가요?'
    ],
    answer: 'YzNkaGJtaDVaSEp2',
    hint: [
      '십자말풀이를 푼 다음 추리해보세요.'
    ]
  },
  {
    title: '15-1. 로이',
    p1: [
      '아무도 신경 안 썼어요, 테레사 닐.', 
      '당신이 잠결에 이름을 말해줬어요. 계속 악몽을 꾸던데.', 
      '그 때나 지금이나 여전하네요.'
    ],
    p2: [
      '50.4619',
      '-3.5253'
    ],
    answer: 'ZEc5eWNYVmhlUT09',
    hint: [
      '전쟁터 한복판.',
      '사람들이 밖에서 죽어나갈 때 같이 숨어 있었잖아요.'
    ]
  },
  {
    title: '15-2. 주인공',
    p1: [
      'Stranger, Roy : 청산가리는?',
      'Neele, Teresa : 청산가리, 소량만 투여해도 5분 후 효과가 나타나요. ',
      '현기증, 경련, 질식 후 죽죠. 또 뭐 재미있는 거 있어요?',
      'Roy : 아미그달린. 아미그달린 독살이 있는 곳엔 늘…',
      'Roy, Teresa : 아몬드향이 나죠!',
      'Roy : 무엇을 쓰는지에 따라 몸에 나타나는 효과도 다르죠.',
      '당신의 주인공은 뭐예요, 테레사 닐?'
    ],
    answer: 'Y0c5cGMyOXU=',
    hint: [
      '4번, "주인공" 문제의 답을 이용하세요. 4번 답과 아주 유사합니다.',
      '6글자'
    ]
  },
  {
    title: '15-3. 탱고',
    p1: [
      '누군가 죽어야 한다면 그건 타인에게 고통을 준 쪽이어야 하지 않을까?',
      '그게 정의 아니에요?',
      '그래요! 죄를 지은 인간들은 대가를 치러야 해요.',
      '',
    ],
    p2: [
      '비소(As) : ',
      '* She is wearing a pearl ring.',
      '* “You should get some rest, Neele.”',
      '',
      '안티몬(Sb) : ',
      '* Agatha Christie: Duchess of death, The Goddess of murder, The Queen of the crime.',
      '* Ariadne fell in love with Theseus and provided him with a sword and ball of thread.',
      '',
      '탈륨(Tl) : ',
      '* The important thing is not a trick, but a motive for murder.',
      '* Mrs. Agatha Mary Clarissa'
    ],
    answer: 'TXpNMU1UZ3g=',
    hint: [
      '이번 문제의 답은 숫자입니다. 숫자를 이어 쓰세요.',
      '6자'
    ]
  },
  {
    title: '15-4. 굿바이 키스',
    p1: [
      '“다들 소문 들었어요?”',
      '“아가사 크리스티요?”',
      '“우울한 여자죠!”',
      '“이번 소설 봤어요? 엉망진창이던데!”',
      '“추리 소설의 여왕? 반칙의 여왕이겠죠.”',
      '“그 여자 이제 완전히 끝났어요!”'
    ],
    p2: [
      '이제 내게 줘, 내 안에 숨겨진 독.',
      '',
      '테레사 닐을 위한 처방 : ',
      '다음을 순서대로 넣어 조제하시오.',
      '아몬드 5알, 활 3, 설탕 4스푼, 금속 1조각, 물 8스푼'
    ],
    answer: 'WkhKbFlXMD0=',
    hint: [
      '와, 이런 독매니아 만나기 쉽지 않은데!',
      '5글자'
    ]
  },
  {
    title: '15-5. 괴물',
    p1: [
      '너 그 날 밤 사실은 전부 죽이고 싶었잖아.',
      '살의에 휩싸여 휘갈겨썼던 너의 계획처럼…',
      '미궁 속의 티타임, 난 그게 네 유작이 되는 게 싫어.',
      '…',
      '로이, 우리 다시 파티를 시작하자.'
    ],
    img: [
      '15_5.png'
    ],
    answer: 'Ym1sbmFIUnRZWEps',
    hint: [
      '글자가 적힌 색깔들을 영문으로 적어 보세요.',
      '9글자'
    ]
  },
  {
    title: '15-6. 아가사 크리스티',
    p1: [
      '아가사, 좀 더 웃어줘.',
      '날 잊지 마.',
    ],
    p2: [
      'UR MANITO'
    ],
    answer: 'YldsdWIzUmhkWEk9',
    hint: [
      '주어진 글자를 분리해 다시 배치해보세요.'
    ]
  },
  {
    title: '16. 미궁 속의 티타임',
    p1: [
      '나는 사람들이 미궁에 들어가고, 원하는 것을 얻어 다시 밖으로 나오는 여정에서 종종 쓰이는 실입니다.',
      '나를 쓰지 않고도 자기가 어디 있었는지 기억하는 사람도 있습니다.', 
      '미궁에 따라서는 내가 아예 존재하지 않을 때도 있어요.',
      '나는 무엇일까요?'
    ],
    answer: 'WW05dmEyMWhjbXM9',
    hint: [
      '두 단어의 합성어입니다.',
      '아가사가 하는 일을 생각해 보세요.'
    ]
  },
  {
    title: '끝',
    p1: [
      '미궁을 탈출하셨습니다. 축하합니다. :)',
      '',
      '아래 실뭉치를 클릭하면 입구로 돌아갑니다.',
      '입구로 돌아가면 다시 한 번 미궁을 탈출해야 본 화면으로 올 수 있으므로 주의하시기 바랍니다.',
      '본 화면을 "시간이 나오도록" 캡쳐해서 트위터의 @_apparitionSky로 DM을 보내주시면 해설지를 드립니다.',
      '',
      '미궁 게임에 사용된 폰트 정보는 나비를 클릭하시면 볼 수 있습니다.',
      '',
      '미궁 게임에 사용된 이미지는 개인 제작과 flaticon 무료 벡터 이미지입니다.',
      '벡터 이미지의 정보는 다음과 같습니다.',
      '나비 (Freepik) https://www.flaticon.com/free-icons/insect',
      '실뭉치 (Freepik) https://www.flaticon.com/free-icons/yarn',
      '칼 (Freepik) https://www.flaticon.com/free-icons/sword',
      '시계 (Freepik) https://www.flaticon.com/free-icons/clock',
      '타자기 (Maxim Basinski Premium) https://www.flaticon.com/free-icons/typewriter',
      '갈매기 (Good Ware) https://www.flaticon.com/free-icons/seagull',
      '돈 (Kiranshastry) https://www.flaticon.com/free-icons/euro',
      '가방 (Freepik) https://www.flaticon.com/free-icons/bag',
      '',
      '미궁 탈출을 함께해주셔서 감사합니다.',
      '아직 미궁을 탐험하는 분들께는 정답 대신 힌트를 알려주시면 감사하겠습니다. 붉은 실이 되어주세요. :)',
      '',
      '#미궁속의티타임',
      '@_apparitionSky from Twitter © 2022'
    ],
    answer: '',
    hint: [
      '기본 (제주명조)',
      '신문기사 제목 (조선로고체) // 기사 본문 (조선일보명조체)',
      '레이몬드의 손편지 (강원교육새음체)',
      '평면도, 테레사의 신문 광고 (Noto Sans KR)',
    ]
  },
]

export default riddle;
